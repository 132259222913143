import React, { useEffect } from 'react';

import { graphql } from 'gatsby';
import PropTypes from 'prop-types';

import Layout from '../../../Layout';
import Loader from '../../../Molecules/Loader/Loader';

const MediaPage = ({ data }) => {
  const documentsArray = data.allDocuments.edges;

  useEffect(() => {
    window.location.replace(documentsArray[0]?.node?.relationships?.field_media_document?.uri?.url);
  }, []);
  return (
    <Layout>

      <Loader customClass="largeMargin" />

    </Layout>
  );
};

MediaPage.propTypes = {
  pageContext: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
};

export const query = graphql`
  query ArticleTemplateQuery(
    $documentsID: [Int!]
  ) {

    allDocuments: allMediaDocument(
      filter: {
        langcode: { eq: "fr" }
        drupal_internal__mid: { in: $documentsID }
      }
    ) {
      edges {
        node {
          langcode
          field_media_document {
            description
          }
          relationships {
            field_media_document {
              filesize
              filename
              filemime
              uri {
                url
              }
            }
          }
          drupal_internal__mid
        }
      }
    }
  }
`;

export default MediaPage;
